import React, { RefObject } from 'react';

import { Level, UserPosition } from '@wemap/geo';
import { rad2deg } from '@wemap/maths';
import { AbsolutePosition, AbsolutePositionProvider } from '@wemap/providers';
import { TimeUtils } from '@wemap/utils';


const Positions = [
    {
        name: 'Wemap (outdoor)',
        coords: new UserPosition(43.6091955, 3.8841255, 1.5)
    },
    {
        name: 'QR Code Wemap (lvl 2)',
        coords: new UserPosition(43.6091812, 3.8841376, 1.5, 2)
    },
    {
        name: 'Gare de Lyon (RER-A)',
        coords: new UserPosition(48.8442365, 2.3728267, 1.5, -2)
    }
];

const fps = 10;

type Props = React.FC<Record<string, never>>;

class DetailsPositionComponent extends React.PureComponent<Props> {
    
    accuracyRef: RefObject<HTMLSpanElement>;
    altitudeRef: RefObject<HTMLSpanElement>;
    bearingRef: RefObject<HTMLSpanElement>;
    latitudeRef: RefObject<HTMLSpanElement>;
    levelRef: RefObject<HTMLSpanElement>;
    longitudeRef: RefObject<HTMLSpanElement>;
    selectRef: RefObject<HTMLSelectElement>;
    timeRef: RefObject<HTMLSpanElement>;

    providersId?: number;
    renderLoopId?: number;
    timeoutLoopId?: number;

    position: AbsolutePosition | null = null;
    positionDirty = true;

    constructor(props: Props) {
        super(props);

        this.latitudeRef = React.createRef();
        this.longitudeRef = React.createRef();
        this.altitudeRef = React.createRef();
        this.levelRef = React.createRef();
        this.bearingRef = React.createRef();
        this.accuracyRef = React.createRef();
        this.timeRef = React.createRef();
        this.selectRef = React.createRef();
    }

    componentDidMount() {

        this.position = AbsolutePositionProvider.lastEvent ? AbsolutePositionProvider.lastEvent : null;

        this.providersId = AbsolutePositionProvider.addEventListener(
            position => {
                this.positionDirty = true;
                this.position = position;
            },
            () => { /* do nothing */ },
            true
        );

        this.renderLoop();
    }

    componentWillUnmount() {
        if (this.timeoutLoopId) {
            clearTimeout(this.timeoutLoopId);
        }
        this.renderLoopId && cancelAnimationFrame(this.renderLoopId);
        AbsolutePositionProvider.removeEventListener(this.providersId);
    }

    renderLoop = () => {
        this.renderPosition();
        this.timeoutLoopId = window.setTimeout(() => {
            delete this.timeoutLoopId;
            this.renderLoopId = requestAnimationFrame(this.renderLoop);
        }, 1000 / fps);
    }

    renderPosition = () => {

        if (!this.positionDirty) {
            return;
        }

        const position = this.position;
        if (position) {
            this.latitudeRef.current!.innerHTML = `${position.lat.toFixed(7)}°`;
            this.longitudeRef.current!.innerHTML = `${position.lng.toFixed(7)}°`;
            this.altitudeRef.current!.innerHTML = position.alt ? `${position.alt.toFixed(2)}m` : '';
            this.levelRef.current!.innerHTML = position.level !== null ? Level.toString(position.level)! : '';
            this.bearingRef.current!.innerHTML = position.bearing !== null ? `${rad2deg(position.bearing).toFixed(2)}°` : '';
            this.accuracyRef.current!.innerHTML = `${position.accuracy!.toFixed(2)}m`;
            this.timeRef.current!.innerHTML = `${position.time!.toFixed(2)}s`;
        }

        this.positionDirty = false;
    }

    render() {
        return (
            <div>
                <div className="app-title">Position</div>
                <p>
                    Latitude: <span ref={this.latitudeRef}></span><br />
                    Longitude: <span ref={this.longitudeRef}></span><br />
                    Altitude: <span ref={this.altitudeRef}></span><br />
                    Level: <span ref={this.levelRef}></span><br />
                    Bearing: <span ref={this.bearingRef}></span><br />
                    Accuracy: <span ref={this.accuracyRef}></span><br />
                    Time: <span ref={this.timeRef}></span>
                </p>
                <div>
                    <select ref={this.selectRef}>
                        {Positions.map((option, index) =>
                            <option key={index}
                                value={index}>
                                {option.name}
                            </option>
                        )}
                    </select>
                    <button
                        style={{ marginLeft: '5px' }}
                        onClick={() => this.handleForcePosition()}>
                        Force
                    </button>
                </div>
            </div>
        );
    }

    handleForcePosition() {
        const position = Positions[Number(this.selectRef.current!.value)].coords.clone();
        position.time = TimeUtils.preciseTime() / 1e3;
        position.accuracy = 0;
        AbsolutePositionProvider.feed(position);
    }
}

export default DetailsPositionComponent;
