import { PureComponent } from 'react';

import { Coordinates } from '@wemap/geo';
import { Itinerary } from '@wemap/routers';

import ItineraryStore from '../stores/ItineraryStore.js';
import { MapboxMapWithIndoorAndGeoloc } from '../types.js';

const Mode = {
    Config: 'config',
    WaitUserPositionForStart: 'wait-user-position-for-start',
    SelectStartOnMap: 'select-start-on-map',
    SelectEndOnMap: 'select-end-on-map',
    ItineraryCompute: 'itinerary-compute',
    Itinerary: 'itinerary'
} as const;

type Props = { map: MapboxMapWithIndoorAndGeoloc };
type State = { error: null | string, mode: typeof Mode[keyof typeof Mode] }

class ItineraryComponent extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            error: null,
            mode: ItineraryStore.itinerary ? Mode.Itinerary : Mode.Config,
        };

    }


    componentDidMount() {
        const ItineraryEvents = ItineraryStore.Events;
        ItineraryStore.on(ItineraryEvents.StartChanged, this._onStartChanged);
        ItineraryStore.on(ItineraryEvents.EndChanged, this._onEndChanged);
        ItineraryStore.on(ItineraryEvents.ItineraryChanged, this._onItineraryChanged);
        ItineraryStore.on(ItineraryEvents.UseStairsChanged, this._onUseStairsChanged);
        ItineraryStore.on(ItineraryEvents.ItineraryNotFound, this._onItineraryNotFound);
        ItineraryStore.on(ItineraryEvents.ServerError, this._onServerError);

        if (this.props.map) {
            this.props.map.on('click', e => {
                this.onMapClick(new Coordinates(e.lngLat.lat, e.lngLat.lng));
                this.props.map.getCanvas().style.cursor = '';
            });
        }
    }

    componentWillUnmount() {
        const ItineraryEvents = ItineraryStore.Events;
        ItineraryStore.off(ItineraryEvents.StartChanged, this._onStartChanged);
        ItineraryStore.off(ItineraryEvents.EndChanged, this._onEndChanged);
        ItineraryStore.off(ItineraryEvents.ItineraryChanged, this._onItineraryChanged);
        ItineraryStore.off(ItineraryEvents.UseStairsChanged, this._onUseStairsChanged);
        ItineraryStore.off(ItineraryEvents.ItineraryNotFound, this._onItineraryNotFound);
        ItineraryStore.off(ItineraryEvents.ServerError, this._onServerError);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.mode !== this.state.mode) {
            if (this.state.mode === Mode.SelectStartOnMap || this.state.mode === Mode.SelectEndOnMap) {
                this.props.map.getCanvas().style.cursor = 'pointer';
            }
        }
    }

    _onStartChanged = () => this.forceUpdate();
    _onEndChanged = () => this.forceUpdate();
    _onUseStairsChanged = () => this.forceUpdate();
    _onItineraryNotFound = () => this.setState({ error: ItineraryStore.Events.ItineraryNotFound });
    _onServerError = () => this.setState({ error: ItineraryStore.Events.ServerError });
    _onItineraryChanged = (itinerary: Itinerary | null) => {
        if (itinerary) {
            this.setState({ mode: Mode.Itinerary });
        } else {
            this.setState({ mode: Mode.Config });
        }
    }

    onMapClick(position: Coordinates) {
        if (this.state.mode === Mode.SelectStartOnMap) {
            ItineraryStore.start = position;
            this.setState({ mode: Mode.Config });
        } else if (this.state.mode === Mode.SelectEndOnMap) {
            if (this.props.map.indoor && this.props.map.indoor.getLevel()) {
                position.level = this.props.map.indoor.getLevel();
            }
            ItineraryStore.end = position;
            this.setState({ mode: Mode.Config });
        }
    }

    onUseUserPositionClick() {
        this.setState({ mode: Mode.WaitUserPositionForStart });
        ItineraryStore.retrieveStartFromUserLocation().then(() => {
            this.setState({ mode: Mode.Config });
        });
    }

    render() {

        const configMode = this.state.mode !== Mode.Itinerary;

        return (
            <div>
                <div className="app-title">Itinerary</div>
                {configMode ? this.renderConfig() : this.renderItinerary()}
            </div>
        );
    }

    renderItinerary() {
        return (
            <button
                value=''
                onClick={() => ItineraryStore.remove()}>
                Remove itinerary
            </button>
        );
    }

    renderConfig() {

        const handleComputeClick = () => {
            ItineraryStore.compute();
        };

        const lockedMode = this.state.mode === Mode.SelectEndOnMap
            || this.state.mode === Mode.SelectStartOnMap
            || this.state.mode === Mode.WaitUserPositionForStart
            || this.state.mode === Mode.ItineraryCompute;

        return (
            <div>
                {this.state.error
                    ? <div style={{
                        marginBottom: '5px',
                        color: 'red'
                    }}>
                        {this.state.error === ItineraryStore.Events.ItineraryNotFound
                            ? 'Itinerary not found'
                            : 'Server error'
                        }
                    </div>
                    : null}
                <div>
                    {this.renderStart(lockedMode)}
                </div>

                <div style={{ marginTop: '5px' }}>
                    {this.renderEnd(lockedMode)}
                </div>

                <div style={{ marginTop: '5px' }}>
                    <img id='app-wheelchair-icon'
                        className={!ItineraryStore.useStairs ? 'selected' : undefined}
                        onClick={() => (ItineraryStore.useStairs = !ItineraryStore.useStairs)} />
                </div>

                <div style={{
                    marginTop: '10px',
                    width: '100%',
                    textAlign: 'center'
                }}>
                    <button
                        disabled={!ItineraryStore.start || !ItineraryStore.end || lockedMode}
                        onClick={handleComputeClick}
                    >
                        Compute
                    </button>
                </div>

            </div>
        );
    }

    renderStart(lockedMode: boolean) {

        let buttons;

        if (ItineraryStore.start) {
            buttons = (
                <button
                    disabled={lockedMode}
                    style={{ backgroundColor: '#e57373' }}
                    onClick={() => (ItineraryStore.start = null)}
                >
                    Remove
                </button>
            );
        } else {
            buttons = (
                <>
                    <button
                        disabled={lockedMode}
                        onClick={() => this.onUseUserPositionClick()}
                    >
                        {
                            this.state.mode === Mode.WaitUserPositionForStart ? (
                                <div className="app-loader"
                                    style={{ marginRight: '5px' }}></div>
                            ) : null
                        }
                        User position
                    </button>

                    <button
                        disabled={lockedMode}
                        style={{ marginLeft: '5px' }}
                        onClick={() => this.setState({ mode: Mode.SelectStartOnMap })}
                    >
                        Select on map
                    </button>
                </>
            );
        }

        return (
            <>
                Start:
                <span style={{ marginLeft: '5px' }}>{buttons}</span>
            </>
        );
    }

    renderEnd(lockedMode: boolean) {

        let buttons;

        if (ItineraryStore.end) {
            buttons = (
                <button
                    disabled={lockedMode}
                    style={{ backgroundColor: '#e57373' }}
                    onClick={() => (ItineraryStore.end = null)}
                >
                    Remove
                </button>
            );
        } else {
            buttons = (
                <button
                    disabled={lockedMode}
                    onClick={() => this.setState({ mode: Mode.SelectEndOnMap })}
                >
                    Select on map
                </button>
            );
        }

        return (
            <>
                <div>
                    End:
                    <span style={{ marginLeft: '5px' }}>{buttons}</span>
                </div>
            </>
        );
    }
}

export default ItineraryComponent;
