import React from 'react';
import { Map, Popup, EventData } from 'mapbox-gl';
import { MapServerHandler, IndoorControl } from 'map-gl-indoor';
import { addGeolocationTo, GeolocationControl } from 'map-gl-geolocation';

import { Coordinates, UserPosition } from '@wemap/geo';
import { ItineraryLayer } from '@wemap/map';
import CustomMapProvider from '@wemap/providers/helpers/CustomMapProvider.js';

import { createMapboxStyleButton } from '../ButtonsUtils.jsx';
import ItineraryStore from '../stores/ItineraryStore.js';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'map-gl-geolocation/dist/map-gl-geolocation.css';
import { MapboxMapWithIndoorAndGeoloc } from '../types.js';

const accessToken
    = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';

type Props = {
    maxZoom: number,
    onMapLoaded: (map: MapboxMapWithIndoorAndGeoloc) => void,
    indoorMapsServer: string,
    switchToAr: () => void,
    infoClicked: () => void,
    showControls: boolean,
    forceGeoloc: boolean
}

class MapComponent extends React.Component<Props> {

    map?: MapboxMapWithIndoorAndGeoloc;

    popup?: Popup;

    static defaultProps = {
        maxZoom: 22,
        onMapLoaded: () => { /* do nothing */ },
        switchToAr: () => { /* do nothing */ },
        showControls: true,
        forceGeoloc: false
    };
    
    _customMapProvider: CustomMapProvider;
    _arControl: any;
    _geolocationControl: GeolocationControl;
    _indoorControl: IndoorControl;
    _infoControl: any;
    mapContainer: HTMLDivElement | null = null;

    constructor(props: Props) {
        super(props);
        this._customMapProvider = new CustomMapProvider();
        this._indoorControl = new IndoorControl();
        this._geolocationControl = new GeolocationControl(this._customMapProvider);
    }

    componentDidMount() {

        const map = this.map = new Map({
            accessToken,
            container: this.mapContainer!,
            style: 'mapbox://styles/mapbox/streets-v10',
            maxZoom: this.props.maxZoom
        }) as MapboxMapWithIndoorAndGeoloc;

        MapServerHandler.manage(this.props.indoorMapsServer, map, {
            beforeLayerId: 'housenum-label',
            layersToHide: ['poi-scalerank4-l15', 'poi-scalerank4-l1', 'poi-scalerank3', 'road-label-small'],
            showFeaturesWithEmptyLevel: true
        });
        addGeolocationTo(map);


        map.on('load', async () => this.props.onMapLoaded(map));

        this._arControl = this._createArControl();
        this._infoControl = this._createInfoControl();

        if (this.props.showControls) {
            this._addControls();
        }

        map.on('click', e => this._onContextmenu(e));

        /**
         * Itinerary management
         */
        const itineraryLayer = new ItineraryLayer(map);

        itineraryLayer.start = ItineraryStore.start;
        itineraryLayer.end = ItineraryStore.end;
        itineraryLayer.itinerary = ItineraryStore.itinerary;

        const { Events } = ItineraryStore;
        ItineraryStore.on(Events.StartChanged, coords => itineraryLayer.start = coords);
        ItineraryStore.on(Events.EndChanged, coords => itineraryLayer.end = coords);
        ItineraryStore.on(Events.ItineraryChanged, itinerary => itineraryLayer.itinerary = itinerary);
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.showControls && this.props.showControls) {
            this._addControls();
        } else if (prevProps.showControls && !this.props.showControls) {
            this._removeControls();
        }
        if (!prevProps.forceGeoloc && this.props.forceGeoloc) {
            this._customMapProvider.start();
            this._customMapProvider.on('position.changed', this._customMapProviderPositionChanged);
            this._customMapProvider.on('heading.changed', this._customMapProviderHeadingChanged);
        } else if (prevProps.forceGeoloc && !this.props.forceGeoloc) {
            this._customMapProvider.off('position.changed', this._customMapProviderPositionChanged);
            this._customMapProvider.off('heading.changed', this._customMapProviderHeadingChanged);
        }
    }

    _customMapProviderPositionChanged = ({ position }: { position: UserPosition }) => {
        this.map && (this.map.geolocation.position = position as any);
    }

    _customMapProviderHeadingChanged = ({ heading }: { heading: number }) => {
        this.map && (this.map.geolocation.heading = heading);
    }

    componentWillUnmount() {
        this._customMapProvider.stop();
        if (this.props.showControls) {
            this._removeControls();
        }
        this.map?.remove();
    }

    render() {
        return (
            <div ref={map => (this.mapContainer = map)}
                style={{
                    width: '100%',
                    height: '100%'
                }} />
        );
    }
    get mapboxInstance() {
        return this.map;
    }


    _addControls() {
        if (!this.map) return;
        this.map.addControl(this._arControl);
        this.map.addControl(this._geolocationControl);
        this.map.addControl(this._indoorControl);
        this.map.addControl(this._infoControl);
    }

    _removeControls() {
        if (!this.map) return;
        this.map.removeControl(this._arControl);
        this.map.removeControl(this._geolocationControl);
        this.map.removeControl(this._indoorControl);
        this.map.removeControl(this._infoControl);
    }

    _createArControl() {
        const container = createMapboxStyleButton('app-ar-switcher', 'AR', this.props.switchToAr);
        return {
            onAdd: () => container,
            onRemove: () => container.remove()
        };
    }

    _createInfoControl() {
        const container = createMapboxStyleButton('app-nav-info', 'info', this.props.infoClicked);
        return {
            onAdd: () => container,
            onRemove: () => container.remove()
        };
    }

    _onContextmenu(e: EventData) {
        if (!this.map) return;

        if (this.popup) {
            this.popup.remove();
            delete this.popup;
        }

        if (ItineraryStore.itinerary || ItineraryStore.itineraryComputing) {
            return;
        }

        const coordinates = new Coordinates(e.lngLat.lat, e.lngLat.lng);

        this.popup = new Popup()
            .setLngLat(e.lngLat)
            .setDOMContent(this._generatePopupHtml(coordinates))
            .addTo(this.map);
    }


    _updateCoordinatesWithMapLevel(coordinates: Coordinates) {
        if (this.map && this.map.indoor.getLevel() !== null) {
            coordinates.level = this.map.indoor.getLevel();
        }
    }

    _generatePopupHtml(coordinates: Coordinates) {

        const container = document.createElement('div');

        const navigateButton = document.createElement('div');
        navigateButton.id = 'app-navigate-to-this-point';
        navigateButton.innerHTML = 'Navigate to this point';
        navigateButton.classList.add('app-map-click-list-element');
        navigateButton.onclick = () => {
            this._updateCoordinatesWithMapLevel(coordinates);
            this._navigateTo(coordinates);
            if (this.popup) {
                this.popup.remove();
            }
        };
        container.appendChild(navigateButton);

        const startButton = document.createElement('div');
        startButton.id = 'app-start-from-there';
        startButton.innerHTML = 'Start from there';
        startButton.classList.add('app-map-click-list-element');
        startButton.onclick = () => {
            this._updateCoordinatesWithMapLevel(coordinates);
            ItineraryStore.start = coordinates;
            this._eventuallyCompute();
            if (this.popup) {
                this.popup.remove();
            }
        };
        container.appendChild(startButton);

        const endButton = document.createElement('div');
        endButton.id = 'app-end-to-there';
        endButton.innerHTML = 'End to there';
        endButton.classList.add('app-map-click-list-element');
        endButton.onclick = () => {
            this._updateCoordinatesWithMapLevel(coordinates);
            ItineraryStore.end = coordinates;
            this._eventuallyCompute();
            if (this.popup) {
                this.popup.remove();
            }
        };
        container.appendChild(endButton);

        return container;
    }

    _navigateTo(coordinates: Coordinates) {

        ItineraryStore.end = coordinates;

        ItineraryStore.retrieveStartFromUserLocation()
            .then(() => {
                ItineraryStore.compute();
                this._customMapProvider.start();
            });
    }

    _eventuallyCompute() {
        if (!ItineraryStore.itinerary && ItineraryStore.start && ItineraryStore.end) {
            ItineraryStore.compute();
        }
    }
}

export default MapComponent;
