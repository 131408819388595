import React from 'react';

const createMapboxStyleButton = (className: string, title: string, onClick: () => void) => {
    const container = document.createElement('div');
    container.classList.add('mapboxgl-ctrl');
    container.classList.add('mapboxgl-ctrl-group');

    const imgAr = document.createElement('div');
    imgAr.classList.add(className);

    const buttonAr = document.createElement('button');
    buttonAr.type = 'button';
    buttonAr.title = title;
    buttonAr.onclick = () => {
        if (!imgAr.classList.contains('selected')) {
            imgAr.classList.add('selected');
        } else {
            imgAr.classList.remove('selected');
        }
        onClick();
    };


    buttonAr.appendChild(imgAr);
    container.appendChild(buttonAr);

    return container;
};

type Props = {
    title: string;
    className: string;
    onClick: () => void;
};
type State = {
    selected: boolean;
}
class MapboxStyleButton extends React.Component<Props, State> {
    state = { selected: false };
    render() {
        return (
            <div className='mapboxgl-ctrl mapboxgl-ctrl-group'>
                <button title={this.props.title}
                    onClick={() => {
                        this.props.onClick();
                        this.setState({ selected: !this.state.selected });
                    }}>
                    <div className={this.props.className + (this.state.selected ? ' selected' : '')}></div>
                </button>
            </div>
        );
    }
}

const createReactMapboxStyleButton = (className: string, title: string, onClick: () => void) => {
    return <MapboxStyleButton
        className={className}
        title={title}
        onClick={onClick} />;
};

export {
    createMapboxStyleButton, createReactMapboxStyleButton
};
