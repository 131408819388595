import React from 'react';
import { AmbientLight } from 'three';

import { GeoVirtualContext } from '@wemap/ar';
import ItineraryArrowHandler from '@wemap/ar/helpers/itinerary/ItineraryArrowHandler.js';
import { VpsProvider } from '@wemap/providers';

import ItineraryStore from '../stores/ItineraryStore.js';
import ScanView from './ScanView.jsx';
import { createReactMapboxStyleButton } from '../ButtonsUtils.jsx';
import { Itinerary } from '@wemap/routers';
import { Camera } from '@wemap/camera';
import { Coordinates } from '@wemap/geo';

type Props = {
    switchToMap: () => void,
    infoClicked: () => void,
    onScan: (result: string) => void
}

type State = {
    camera: Camera | null,
    scan: boolean
}

class ArComponent extends React.Component<Props, State> {
    static defaultProps = {
        switchToMap: () => { /* do nothing */ },
        onScan: () => { /* do nothing */ }
    };

    _controlContainer: HTMLDivElement | null = null;
    _itineraryChanged?: (itinerary: Itinerary | null) => void;
    _itineraryEndChanged?: (end: Coordinates | null) => void;
    container: HTMLDivElement | null = null;
    geoVirtualContext?: GeoVirtualContext;

    buttons: { [key: string]: JSX.Element } = {};
    _vpsProviderId?: number;

    constructor(props: Props) {
        super(props);
        this.state = {
            camera: null,
            scan: false
        };

        this._initButtons();
    }

    _initButtons() {
        this.buttons.info = createReactMapboxStyleButton('app-nav-info', 'info', this.props.infoClicked);
        this.buttons.map = createReactMapboxStyleButton('app-map-switcher', 'map', () => this.props.switchToMap());
        this.buttons.scan = createReactMapboxStyleButton('app-scan-button', 'scan', () => this.setState({ scan: true }));
        this.buttons.close = createReactMapboxStyleButton('app-close-button', 'close', () => this.setState({ scan: false }));
    }

    componentDidMount() {

        this.geoVirtualContext = new GeoVirtualContext(this.container!);

        this.geoVirtualContext.camera.positioningFromSensors = true;
        this.geoVirtualContext.camera.orientationFromSensors = true;
        this.geoVirtualContext.scene.add(new AmbientLight(0xFFFFFF));
        this._initItinerary(this.geoVirtualContext);

        this.geoVirtualContext.setBackground('camera');

        this.geoVirtualContext.start();
    }

    _initItinerary(geoVirtualContext: GeoVirtualContext) {

        const itineraryHandler = new ItineraryArrowHandler(geoVirtualContext);

        itineraryHandler.itinerary = ItineraryStore.itinerary;
        this._itineraryChanged = itinerary => itineraryHandler.itinerary = itinerary;
        ItineraryStore.on(ItineraryStore.Events.ItineraryChanged, this._itineraryChanged);

        itineraryHandler.end = ItineraryStore.end;
        this._itineraryEndChanged = end => itineraryHandler.end = end;
        ItineraryStore.on(ItineraryStore.Events.EndChanged, this._itineraryEndChanged);

    }

    componentWillUnmount() {

        ItineraryStore.off(ItineraryStore.Events.ItineraryChanged, this._itineraryChanged!);
        ItineraryStore.off(ItineraryStore.Events.EndChanged, this._itineraryEndChanged!);

        this.geoVirtualContext?.stop();
    }

    componentDidUpdate(nextProps: Props, nextState: State) {
        if (this.state.scan && !nextState.scan) {
            this._vpsProviderId = VpsProvider.addEventListener(() => {
                this.setState({ scan: false });
            });
        } else if (!this.state.scan && nextState.scan) {
            VpsProvider.removeEventListener(this._vpsProviderId);
        }
    }

    render() {

        return (
            <>
                <div className='mapboxgl-control-container'>
                    <div className='mapboxgl-ctrl-top-right'
                        ref={ref => (this._controlContainer = ref)} >
                        {
                            !this.state.scan
                                ? (
                                    <>
                                        {this.buttons.map}
                                        {this.buttons.scan}
                                    </>
                                ) : this.buttons.close
                        }
                        {this.buttons.info}
                    </div>
                </div>

                <div ref={container => (this.container = container)}
                    style={{
                        width: '100%',
                        height: '100%'
                    }} />

                {
                    this.state.scan
                        ? (
                            <ScanView
                                onScan={(str) => {
                                    this.setState({ scan: false });
                                    this.props.onScan(str);
                                }}
                                cameraContainer={this.container!} />
                        ) : ''
                }

            </>
        );
    }
}

export default ArComponent;
