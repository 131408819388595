import { Evented } from 'mapbox-gl';
import Logger from '@wemap/logger';
import {
    PositionSmoother, AbsoluteAttitudeProvider, AbsolutePositionProvider
} from '@wemap/providers';

export default class CustomMapProvider extends Evented {

    attitudeProviderId?: number;
    positionProviderId?: number;
    smoother: PositionSmoother | null = null;

    _started = false;

    start() {

        if (this._started) {
            return;
        }
        this._started = true;

        /**
         * Attitude
         */
        this.attitudeProviderId = AbsoluteAttitudeProvider.addEventListener(
            attitude => this.fire('heading.changed', { heading: attitude.headingDegrees }),
            e => Logger.warn(e.message)
        );

        // There is no sense to show the last known attitude on the map
        // this.fire('heading.changed' : { heading: AbsoluteAttitudeProvider.lastEvent ? AbsoluteAttitudeProvider.lastEvent.data : null});


        /**
         * Position
         */

        this.smoother = new PositionSmoother(position => {
            this.fire('position.changed', { position });
        });

        this.positionProviderId = AbsolutePositionProvider.addEventListener(
            position => this.smoother!.feed(position),
            e => Logger.warn(e.message)
        );

        const lastKnownPosition = AbsolutePositionProvider.lastEvent ? AbsolutePositionProvider.lastEvent : null;
        if (lastKnownPosition) {
            this.fire('position.changed', { position: lastKnownPosition });
        }
    }

    stop() {
        if (!this._started) {
            return;
        }
        this._started = false;

        /**
         * Attitude
         */

        AbsoluteAttitudeProvider.removeEventListener(this.attitudeProviderId);


        /**
         * Position
         */

        AbsolutePositionProvider.removeEventListener(this.positionProviderId);

        this.smoother!.clear();
        this.smoother = null;
    }
}
