import React, { RefObject } from 'react';

import { AbsoluteHeading } from '@wemap/geo';
import { deg2rad, rad2deg } from '@wemap/maths';
import { AbsoluteAttitude, AbsoluteAttitudeProvider } from '@wemap/providers';
import { TimeUtils } from '@wemap/utils';


const Orientations = [
    {
        name: 'QR Code Wemap (lvl 2)',
        heading: 191.9
    },
    {
        name: 'Gare de Lyon (RER-A)',
        heading: 41.6
    }
];

const fps = 10;

type Props = React.FC<Record<string, never>>;

class DetailsAttitudeComponent extends React.PureComponent<Props> {

    accuracyRef: RefObject<HTMLSpanElement>;
    eulerRef: RefObject<HTMLSpanElement>;
    headingRef: RefObject<HTMLSpanElement>;
    quaternionRef: RefObject<HTMLSpanElement>;
    selectRef: RefObject<HTMLSelectElement>;
    timeRef: RefObject<HTMLSpanElement>;

    providersId?: number;
    renderLoopId?: number;
    timeoutLoopId?: number;

    attitude: AbsoluteAttitude | null = null;
    attitudeDirty = true;

    constructor(props: Props) {
        super(props);

        this.quaternionRef = React.createRef();
        this.eulerRef = React.createRef();
        this.headingRef = React.createRef();
        this.accuracyRef = React.createRef();
        this.timeRef = React.createRef();
        this.selectRef = React.createRef();
    }

    componentDidMount() {

        this.attitude = AbsoluteAttitudeProvider.lastEvent ? AbsoluteAttitudeProvider.lastEvent : null;

        this.providersId = AbsoluteAttitudeProvider.addEventListener(
            attitude => {
                this.attitudeDirty = true;
                this.attitude = attitude;
            },
            () => { /* do nothing */ },
            true
        );

        this.renderLoop();
    }

    componentWillUnmount() {
        if (this.timeoutLoopId) {
            clearTimeout(this.timeoutLoopId);
        }
        this.renderLoopId && cancelAnimationFrame(this.renderLoopId);
        AbsoluteAttitudeProvider.removeEventListener(this.providersId);
    }

    renderLoop = () => {
        this.renderAttitude();
        this.timeoutLoopId = window.setTimeout(() => {
            delete this.timeoutLoopId;
            this.renderLoopId = requestAnimationFrame(this.renderLoop);
        }, 1000 / fps);
    }

    renderAttitude = () => {

        if (!this.attitudeDirty) {
            return;
        }

        const attitude = this.attitude;
        if (attitude) {
            const q = attitude.quaternion;
            this.quaternionRef.current!.innerHTML = `[${q[0].toFixed(3)}, ${q[1].toFixed(3)}, ${q[2].toFixed(3)}, ${q[3].toFixed(3)}]`;

            const euler = attitude.eulerAnglesDegrees;
            this.eulerRef.current!.innerHTML = `[${euler[0].toFixed(2)}°, ${euler[1].toFixed(2)}°, ${euler[2].toFixed(2)}°]`;

            this.headingRef.current!.innerHTML = `${attitude.headingDegrees.toFixed(2)}°`;

            this.accuracyRef.current!.innerHTML = `${rad2deg(attitude.accuracy!).toFixed(1)}°`;
            this.timeRef.current!.innerHTML = `${attitude.time!.toFixed(2)}s`;
        }

        this.attitudeDirty = false;
    }

    render() {
        return (
            <div>
                <div className="app-title">Attitude</div>

                <p>
                    Quat: <span ref={this.quaternionRef}></span><br />
                    Euler: <span ref={this.eulerRef}></span><br />
                    Heading: <span ref={this.headingRef}></span><br />
                    Accuracy: <span ref={this.accuracyRef}></span><br />
                    Time: <span ref={this.timeRef}></span>
                </p>

                <div>
                    <select ref={this.selectRef}>
                        {Orientations.map((option, index) =>
                            <option key={index}
                                value={index}>
                                {option.name}
                            </option>
                        )}
                    </select>

                    <button
                        style={{ marginLeft: '5px' }}
                        onClick={() => this.handleForceOrientation()}>
                        Force
                    </button>
                </div>
            </div>
        );
    }

    handleForceOrientation() {
        const heading = new AbsoluteHeading(
            deg2rad(Orientations[Number(this.selectRef.current!.value)].heading),
            TimeUtils.preciseTime() / 1e3,
            0
        );
        AbsoluteAttitudeProvider.feed(heading);
    }
}

export default DetailsAttitudeComponent;
