import React from 'react';

import { UserPosition, AbsoluteHeading, UserPositionJson } from '@wemap/geo';
import { deg2rad } from '@wemap/maths';
import { AbsolutePositionProvider, AbsoluteAttitudeProvider, VpsProvider } from '@wemap/providers';
import { TimeUtils } from '@wemap/utils';

import ArComponent from './ar/ArComponent.jsx';
import MapComponent from './map/MapComponent.jsx';
import DetailsComponent from './details/DetailsComponent.jsx';
import { MapboxMapWithIndoorAndGeoloc } from './types.js';

import './css/App.css';
import '../fullscreen.css';

type Props = {
    geoQrcodes: {
        str: string,
        coords: UserPositionJson,
        heading: number
    }[]
}

type State = {
    details: boolean;
    map: MapboxMapWithIndoorAndGeoloc | null;
    mode: 'map' | 'ar'
}

class MainComponent extends React.Component<Props, State> {

    mapComponent: MapComponent | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            details: false,
            map: null,
            mode: 'map'
        };
        VpsProvider.useCoarsePose = false;
        VpsProvider.endpoint = 'https://vps-api.maaap.it/wemap-montpellier/geopose';
    }

    componentDidMount() {
        if (this.state.mode === 'map') {
            this._setMapOptions();
        } else {
            this._setArOptions();
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.mode === 'map' && this.state.mode === 'ar') {
            this._setArOptions();
        } else if (prevState.mode === 'ar' && this.state.mode === 'map') {
            this._setMapOptions();
        }
    }

    _setArOptions() {
        if (!this.mapComponent) {
            return;
        }
        (this.mapComponent?.map?.geolocation as any).centerOnCamera();
    }

    _setMapOptions() {
        if (!this.mapComponent) {
            return;
        }
        (this.mapComponent?.map?.geolocation as any).centerOnCamera();
    }


    _parseQrCode(str: any) {
        if (!this.props.geoQrcodes) {
            return;
        }

        const qrCode = this.props.geoQrcodes.find(({ str: _str }) => (str === _str));
        if (!qrCode) {
            console.error('QRcode not found: ', str);
            return;
        }

        const position = UserPosition.fromJson(qrCode.coords);
        position.time = TimeUtils.preciseTime() / 1e3;
        position.accuracy = 0;
        AbsolutePositionProvider.feed(position);
        if ('heading' in qrCode) {
            const heading = new AbsoluteHeading(
                deg2rad(qrCode.heading),
                TimeUtils.preciseTime() / 1e3,
                0
            );
            AbsoluteAttitudeProvider.feed(heading);
        }
    }

    render() {
        return (
            <>
                {
                    this.state.mode === 'ar'
                        ? (
                            <div id="app-ar-container">
                                <ArComponent
                                    switchToMap={() => this.setState({ mode: 'map' })}
                                    infoClicked={() => this.setState({ details: !this.state.details })}
                                    onScan={(str) => this._parseQrCode(str)}
                                />
                            </div>
                        )
                        : null
                }
                <div id='app-map-container'
                    className={this.state.mode === 'ar' ? 'with-ar' : ''}>
                    <MapComponent
                        ref={mapComponent => {
                            this.mapComponent = mapComponent;
                            mapComponent?.map?.resize();
                        }}
                        onMapLoaded={map => this.setState({ map })}
                        switchToAr={() => this.setState({ mode: 'ar' })}
                        indoorMapsServer={'https://api.thibaud-michel.com/geojson-indoor-maps'}
                        infoClicked={() => this.setState({ details: !this.state.details })}
                        showControls={this.state.mode === 'map'}
                        forceGeoloc={this.state.mode === 'ar'}
                    />
                </div>
                {this.state.details && this.state.map ? (
                    <div id='app-menu-container'>
                        <div id='app-details-container'>
                            <DetailsComponent map={this.state.map} />
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

export default MainComponent;
