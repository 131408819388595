import React from 'react';

import { QrCodeScanner, BarcodeFormat, SharedCameras } from '@wemap/camera';

type Props = {
    cameraContainer: HTMLElement;
    onScan: (result: string) => void
}

class ScanView extends React.Component<Props> {

    qrCodeScanner?: QrCodeScanner;

    componentDidMount() {
        const camera = SharedCameras.getCameraByContainer(this.props.cameraContainer)!;

        this.qrCodeScanner = new QrCodeScanner(camera);
        this.qrCodeScanner.start(500, [BarcodeFormat.QR_CODE]);
        this.qrCodeScanner.on('scan', this.props.onScan);
    }

    componentWillUnmount() {
        this.qrCodeScanner?.off('scan', this.props.onScan);
        this.qrCodeScanner?.stop();
    }

    render() {
        return (
            <div id='app-scan-view'>
                <div id='app-scan-view-corners'>
                    <div id='app-scan-view-corner-top-left'></div>
                    <div id='app-scan-view-corner-top-right'></div>
                    <div id='app-scan-view-corner-bottom-left'></div>
                    <div id='app-scan-view-corner-bottom-right'></div>
                </div>
            </div>
        );
    }
}

export default ScanView;
