import EventEmitter from 'events';

import { Coordinates, Level } from '@wemap/geo';
import { AbsolutePositionProvider, MapMatchingHandler } from '@wemap/providers';
import {
    Itinerary,
    RouterRequest,    
    WemapMultiRemoteRouter
} from '@wemap/routers';

const WEMAP_MULTI_URL = 'https://multi-routers.getwemap.com';
const OSRM_URL = 'https://routing-orsm.getwemap.com';

interface MultiRouterResponse {
    itineraries: Array<Parameters<typeof Itinerary['fromJson']>[0]>;
}

type Event = typeof ItineraryStore["Events"][keyof ItineraryStore["Events"]];
declare interface ItineraryStore {
    on(event: Event, callback: (cb: any) => void): this;
    off(event: Event, callback: (cb: any) => void): this;
}

class ItineraryStore extends EventEmitter {

    _start: Coordinates | null = null;
    _end: Coordinates | null = null;
    _itinerary: Itinerary | null = null;
    _useStairs = true;

    static Events = {
        StartChanged: 'itinerary.start.changed',
        EndChanged: 'itinerary.end.changed',
        ItineraryChanged: 'itinerary.changed',
        ItineraryNotFound: 'itinerary.not.found',
        ItineraryComputing: 'itinerary.computing',
        ServerError: 'itinerary.server.error',
        UseStairsChanged: 'itinerary.use.stairs.changed'
    } as const;

    itineraryComputing = false;
    Events = ItineraryStore.Events;

    set start(start) {
        console.log('start changed for', start);
        this._start = start;
        this.emit(ItineraryStore.Events.StartChanged, start);
    }

    get start() {
        return this._start;
    }

    async retrieveStartFromUserLocation() {

        const userPosition = AbsolutePositionProvider.lastEvent ? AbsolutePositionProvider.lastEvent : null;
        if (userPosition) {
            this.start = userPosition;
            return Promise.resolve();
        }

        return new Promise<void>((resolve, reject) => {

            const rejectFn = () => {
                AbsolutePositionProvider.removeEventListener(providerId);
                reject();
            };

            this.on(ItineraryStore.Events.StartChanged, rejectFn);

            const providerId = AbsolutePositionProvider.addEventListener(position => {
                this.off(ItineraryStore.Events.StartChanged, rejectFn);
                AbsolutePositionProvider.removeEventListener(providerId);
                this.start = position;
                resolve();
            });
        });
    }

    set end(end) {
        console.log('end changed for', end);
        this._end = end;
        this.emit(ItineraryStore.Events.EndChanged, end);
    }

    get end() {
        return this._end;
    }


    get itinerary() {
        return this._itinerary;
    }

    get isComputing() {
        return this.itineraryComputing;
    }

    /**
     * @param {Boolean}
     */
    set useStairs(useStairs) {
        this._useStairs = useStairs;
        this.emit(ItineraryStore.Events.UseStairsChanged, useStairs);
    }

    /**
     * @return {Boolean}
     */
    get useStairs() {
        return this._useStairs;
    }

    async compute() {

        const start = this.start;
        const end = this.end;

        if (!start || !end) {
            return;
        }

        this.itineraryComputing = true;
        this.emit(ItineraryStore.Events.ItineraryComputing, true);

        const routerRequest: RouterRequest = {
            origin: start,
            destination: end,
            travelMode: 'WALK',
            itineraryModifiers: {
                avoidStairs: !this.useStairs,
                avoidEscalators: !this.useStairs
            }
        };

        const routerResponse = await fetch(`${WEMAP_MULTI_URL}/v2/compute-itineraries`, {
            method: 'POST',
            body: JSON.stringify(routerRequest)
        }).then((resp) => resp.json()) as MultiRouterResponse;

        this.itineraryComputing = false;
        this.emit(ItineraryStore.Events.ItineraryComputing, false);

        if (!routerResponse) {
            this.emit(ItineraryStore.Events.ServerError);
            this.remove();
            return;
        }

        if (routerResponse.itineraries.length === 0) {
            this.emit(ItineraryStore.Events.ItineraryNotFound);
            this.remove();
            return;
        }

        const itineraries = routerResponse.itineraries.map((itinerary) => Itinerary.fromJson(itinerary));
        const itinerary = itineraries[0];
        if (itinerary.coords.length > 0) {
            const firstCoords = itinerary.coords[0];
            const lastCoords = itinerary.coords[itinerary.coords.length - 1];

            if (this.start && !Level.equals(firstCoords.level, start.level)) {
                this.start.level = firstCoords.level;
                this.emit(ItineraryStore.Events.StartChanged, start);
            }

            if (this.end && !Level.equals(lastCoords.level, end.level)) {
                this.end.level = lastCoords.level;
                this.emit(ItineraryStore.Events.EndChanged, end);
            }
        }

        this._itinerary = itinerary;
        this.emit(ItineraryStore.Events.ItineraryChanged, itinerary);

        MapMatchingHandler.itinerary = itinerary;
    }

    remove() {
        this._itinerary = null;
        this.emit(ItineraryStore.Events.ItineraryChanged, null);
        MapMatchingHandler.itinerary = null;
    }
}

export default new ItineraryStore();
