import { Group, Object3D } from 'three';

import { GeoVirtualContext, GeoObject3D } from '@wemap/ar';
import { Coordinates, Utils as GeoUtils } from '@wemap/geo';
import { Itinerary } from '@wemap/routers';

import ItineraryHandler from './ItineraryHandler.js';
import EndPoint from '../assets/EndPoint.js';
import LivemapItineraryGuide from '../livemap/assets/LivemapItineraryGuide.js';

class ItineraryArrowHandler extends ItineraryHandler {

    _itineraryCoreGroup: Group;

    _arrowObjectPr: Promise<GeoObject3D>;
    _endObjectPr: Promise<GeoObject3D>;
    _endObject: GeoObject3D | null = null;

    constructor(geoVirtualContext: GeoVirtualContext, arrowObj: Object3D | null = null, endObj: Object3D | null = null) {
        super(geoVirtualContext);

        this._itineraryCoreGroup = new Group();
        this._itineraryCoreGroup.name = 'itinerary-core';

        this._itineraryGroup.add(this._itineraryCoreGroup);

        this._arrowObjectPr = !arrowObj
            ? ItineraryArrowHandler.createDefaultArrowMesh()
            : Promise.resolve(GeoObject3D.fromObject3D(arrowObj));
        this._endObjectPr = !endObj
            ? ItineraryArrowHandler.createEndModel(geoVirtualContext)
            : Promise.resolve(GeoObject3D.fromObject3D(endObj));
    }

    static async createDefaultArrowMesh() {
        return GeoObject3D.fromObject3D(await LivemapItineraryGuide.draw());
    }

    static async createEndModel(geoVirtualContext: GeoVirtualContext) {
        const mesh = await EndPoint.draw();
        const geoMesh = GeoObject3D.fromObject3D(mesh);
        geoMesh.faceCamera(true, geoVirtualContext.camera, {
            updateMethod: 'on-render',
            geoVirtualContext
        });
        return geoMesh;
    }

    async _drawItinerary(itinerary: Itinerary | null) {

        for (const child of this._itineraryCoreGroup.children) {
            this._itineraryCoreGroup.remove(child);
        }

        if (!itinerary) {
            return;
        }

        const samples = GeoUtils.sampleRoute(itinerary.coords, 1.6);
        for (const coords of samples) {

            const mesh = (await this._arrowObjectPr).clone();
            const geoMesh = GeoObject3D.fromObject3D(mesh);

            coords.alt = 0;
            geoMesh.coordinates = coords;
            geoMesh.heading = coords.bearing;

            this._itineraryCoreGroup.add(mesh);
        }
    }

    _drawStart(): Promise<void> {
        return Promise.resolve();
    }

    async _drawEnd(coords: Coordinates) {

        if (!coords && this._endObject) {
            this._itineraryGroup.remove(this._endObject);
        } else if (coords && !this._endObject) {
            this._endObject = await this._endObjectPr;
            this._itineraryGroup.add(this._endObject);
        }

        if (coords && this._endObject) {
            const floorCoords = coords.clone();
            floorCoords.alt = 0;
            this._endObject.coordinates = floorCoords;
        }
    }
}

export default ItineraryArrowHandler;
