import React from 'react';

import DetailsPositionComponent from './DetailsPositionComponent.jsx';
import DetailsAttitudeComponent from './DetailsAttitudeComponent.jsx';
import ItineraryComponent from './ItineraryComponent.jsx';
import { MapboxMapWithIndoorAndGeoloc } from '../types.js';

type Props = { map: MapboxMapWithIndoorAndGeoloc };

class DetailsComponent extends React.Component<Props> {

    render() {

        return (
            <>

                <DetailsPositionComponent />

                <DetailsAttitudeComponent />

                <ItineraryComponent map={this.props.map} />

            </>
        );
    }

}

export default DetailsComponent;
